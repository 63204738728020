import { VariantProps, cva } from 'class-variance-authority'
import * as React from 'react'

import { cn } from '@/utils'

const buttonVariants = cva(
  'inline-flex items-center justify-center rounded-md text-sm font-medium transition-colors focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-ring focus-visible:ring-offset-2 disabled:opacity-50 disabled:pointer-events-none ring-offset-background',
  {
    variants: {
      variant: {
        default: 'bg-primary text-primary-foreground hover:bg-primary/90',
        destructive: 'bg-red-dark text-destructive-foreground hover:bg-red-error/90',
        outline: 'border border-brand-main bg-transparent hover:bg-brand-main text-accent-foreground',
        secondary: 'bg-gray-800 text-secondary-foreground hover:bg-gray/80',
        ghost: '',
        brand: cn('text-primary-foreground', 'bg-brand-main hover:bg-brand-main/90 active:bg-brand-main/80'),
        brandDark: cn('text-white', 'bg-brand-dark hover:bg-brand-main/90 active:bg-brand-main/80'),
        link: 'underline-offset-4 hover:underline text-primary'
      },
      size: {
        default: 'h-10 py-2 px-4',
        sm: 'h-9 px-3 rounded-md',
        lg: 'h-11 px-8 rounded-md'
      }
    },
    defaultVariants: {
      variant: 'default',
      size: 'default'
    }
  }
)

const Loader = () => (
  <div className='flex gap-1'>
    <div className='h-3 w-3 bg-black rounded-full animate-bounce [animation-delay:-0.3s]' />
    <div className='h-3 w-3 bg-black rounded-full animate-bounce animate-infinite [animation-delay:-0.15s]' />
    <div className='h-3 w-3 bg-black rounded-full animate-bounce animate-infinite' />
  </div>
)

export interface ButtonProps
  extends React.ButtonHTMLAttributes<HTMLButtonElement>,
    VariantProps<typeof buttonVariants> {
  loading?: boolean
  asChild?: boolean
}

const Button = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant, size, loading, asChild = false, children, ...props }, ref) => {
    return (
      <button
        type={!asChild ? 'button' : undefined}
        className={cn(
          buttonVariants({ variant, size, className }),
          props.disabled && 'bg-gray-400 pointer-events-none'
        )}
        disabled={props.disabled}
        ref={ref}
        {...props}
      >
        {loading ? <Loader /> : children}
      </button>
    )
  }
)
Button.displayName = 'Button'

export { Button, buttonVariants }
