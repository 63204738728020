import { InitOptions } from 'i18next'

export const fallbackLng = 'en' as const
export const languages = [fallbackLng] as const
export const defaultNS = 'common' as const
export const cookieName = 'i18next' as const

export type Language = (typeof languages)[number]

export function getOptions(lng: Language = fallbackLng, ns: string | string[] = defaultNS): InitOptions {
  return {
    // debug: true,
    supportedLngs: languages,
    // preload: languages,
    fallbackLng,
    lng,
    fallbackNS: defaultNS,
    defaultNS,
    ns
    // backend: {
    //   projectId: '01b2e5e8-6243-47d1-b36f-963dbb8bcae3'
    // }
  }
}
