import { TorexRegistryContext } from '@/components/providers/TorexRegistryProvider'
import { ChainId } from '@/config/networks'
import { useCallback, useContext, useMemo } from 'react'
import { Address } from 'viem'
import { useChainId } from 'wagmi'

export const useAllTorexes = () => {
  const chainId = useChainId() as ChainId
  const context = useContext(TorexRegistryContext)

  const getTorexByTokens = useCallback(
    (inToken: Address, outToken: Address) => {
      const torexes = Object.entries(context[chainId])

      const result = torexes.find(
        ([_, torex]) => torex.inboundToken.address === inToken && torex.outboundToken.address === outToken
      )

      return result?.[1]
    },
    [context, chainId]
  )

  const getTorexByDistribtionPool = useCallback(
    (pool: Address) => {
      const torexes = Object.entries(context[chainId])

      const result = torexes.find(([_, torex]) => torex.distribuitionPool === pool)

      return result?.[1]
    },
    [context, chainId]
  )

  const torexes = useMemo(() => context[chainId] ?? {}, [context, chainId])

  const numOfTorexes = useMemo(() => Object.keys(torexes).length, [torexes])
  const numOfBidirectionalTorexes = useMemo(
    () =>
      new Set(
        Object.values(torexes).map(({ inboundToken, outboundToken }) =>
          inboundToken.address > outboundToken.address
            ? `${outboundToken.address}-${inboundToken.address}`
            : `${inboundToken.address}-${outboundToken.address}`
        )
      ).size,
    [torexes]
  )

  return {
    torexes,
    getTorexByTokens,
    getTorexByDistribtionPool,
    numOfTorexes,
    numOfBidirectionalTorexes
  }
}
